@import url("https://fonts.googleapis.com/css?family=Inter:wght@400,500,600,700&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: "Inter", sans-serif;
}
html,
body {
  height: 100%;
}
body {
  width: 100%;
  height: fit-content;
  background-color: #dbfa66 !important;
  overflow-x: hidden;
  /* margin-top: -20px !important; */
}
#root {
  overflow-x: hidden;
  height: 100%;
}

.container-wrapper {
  padding: 50px 0 0 30px;
  background-image: url("../public/assets/images/app-bgnew.png");
  /* -webkit-linear-gradient(bottom, #547aa1, #4773a0); */
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  margin-bottom: 0px;
}

.section-img-1 {
  position: relative;
}
.section-img-1 img {
  width: 647px;
  object-fit: cover;
  position: static;
  margin-left: 60px;
  z-index: 10;
}

.section-img-2 {
  width: 660px !important;
  /* margin-top: auto !important; */
  object-fit: cover;
  position: absolute;
  right: 0;
  bottom: 0;
}

.section-img-3 {
  /* width: 450px !important; */
  margin-top: auto;
  margin-left: -12px;
  object-fit: cover;
  position: relative;
  bottom: -13px;
  left: -36px;
}

.tg-btn-enroll {
  background-color: transparent !important;
  border: none !important;
  font-size: 20px;
}

.tg-btn-enroll:hover,
.tg-btn-enroll:focus {
  color: #ffff !important;
}

.sub-content-a {
  color: #dbfa66;
  /* position: relative; */
}

.asterisk {
  position: absolute;
  top: 100px;
  font-size: 24px;
  font-weight: normal;
}

.sub-content-ab {
  color: #ffff;
  font-size: 4.5rem;
  font-weight: bold;
  line-height: 1;
}

.sub-content-b {
  color: #ffff;
  font-size: 4rem;
  font-weight: bold;
  line-height: 1;
}

.sub-content-text {
  color: #ffff;
  font-size: 23px;
  margin: 12px 0;
}

.sub-content-text-a {
  color: #ffff;
  font-weight: 600;
  font-size: 26px;
  /* margin-bottom: 8px; */
  line-height: 40px;
}

.sub-content-last {
  font-size: 21px;
  color: #fff;
  margin-bottom: 0.5rem;
}
.sub-content-input {
  width: 420px;
  height: 70px;
  font-size: 26px;
  text-align: center;
}

.sub-content-button {
  width: 190px;
  height: 70px;
  background-color: #dbfa66;
  border: 2px solid #e1e8f5;
  color: #101e42;
  font-size: 21px;
  font-weight: bold;
}

.toggle-btn {
  text-align: left !important;
  margin-left: 2.5rem;
}

.sub-content-button:hover,
.sub-content-button:focus {
  background-color: #101e42 !important;
  color: #dbfa66 !important;
  /* border: none !important; */
}
.bounce {
  width: 100px;
  height: 100px;
  animation: bounce 0.8s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 12px, 0);
  }
}

.semicircle {
  width: 100px;
  height: 55px;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  background-color: inherit;
  border-radius: 0 0 50px 50px;
  background-color: #0078d5;
  position: relative;
  bottom: 15px;
  border: none;
  cursor: pointer;
  /* margin-bottom: -55px; */
  z-index: 1;
}

.semi-content {
  font-size: 45px;
  color: #fff;
  position: absolute;
  display: flex;
  align-items: flex-end;
  left: 26%;
}

.semi-concave {
  width: 102px;
  height: 50px;
  border-radius: 0 0 50px 50px;
  background-color: #dbfa66;
  margin: 0 auto;
  position: relative;
  top: -5px;
  z-index: 0;
}
.semicircle-a {
  width: 100px;
  height: 65px;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  border-radius: 0 0 50px 50px;
  background: linear-gradient(
    90deg,
    rgba(8, 76, 142, 1) 0%,
    rgba(9, 71, 133, 1) 100%
  );
  position: relative;
  bottom: 28px;
  border: none;
  cursor: pointer;
  margin-bottom: -55px;
  z-index: 1;
}

.semi-content-a {
  font-size: 45px;
  color: #fff;
  position: absolute;
  display: flex;
  align-items: flex-end;
  left: 26%;
}

.semi-concave-a {
  width: 102px;
  height: 50px;
  border-radius: 0 0 50px 50px;
  background-color: #dbfa66;
  margin: 0 auto;
  position: relative;
  top: 0px;
}
.semicircle-b {
  width: 109px;
  height: 52px;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  background-color: inherit;
  border-radius: 50px 50px 0 0;
  background-color: #dbfa66;
  position: relative;
  bottom: 36px;
  border: none;
  cursor: pointer;
  margin-bottom: -55px;
}

.semi-content-b {
  font-size: 45px;
  color: #fff;
  position: absolute;
  display: flex;
  align-items: flex-end;
  left: 28%;
  z-index: 1;
  top: -53px;
}

.semi-concave-b {
  width: 102px;
  height: 50px;
  border-radius: 50px 50px 0 0;
  background-color: #101e42;
  margin: 0 auto;
  margin-top: -61px;
  position: relative;
  top: 22px;
}
.container-wrapper-cp {
  background-image: url("../public/assets/images/bg-2.png");
  background-color: #e1e8f5;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -10;
  margin-top: -46px;
}

.container-cp-title {
  color: #101e42;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  font-weight: bold;
}

.container-cp-content {
  width: 80%;
  margin: auto;
  color: #101e42;
  font-size: 21px;
  /* margin-bottom: 40px; */
}
.container-cp-card {
  border: 5px solid #0978d4;
  border-radius: 10%;
  width: 388px;
  height: 399px;
  line-height: 56px;
  background-color: #f6f6f9;
}
.container-cp-card-title {
  color: #fff;
  background-color: #0978d4;
  display: flex;
  border-radius: 32px 32px 18px 18px;
  flex-direction: column;
  margin-left: auto;
  padding: 30px 20px;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 44px;
}
.container-cp-span {
  font-size: 40px;
  display: flex;
  justify-content: space-evenly;
  font-weight: 800;
}
.container-cp-list {
  text-align: center;
  align-items: center;
}
.container-cp-img {
  width: auto;
  height: 30px;
}
.app-plan-img {
  width: auto;
  height: 21px;
}
.container-cp-li {
  margin-left: auto;
  /* margin-bottom: 10px; */
  display: flex;
  font-size: 23px;
  align-content: center;
  align-items: center;
  text-align: left;
  gap: 25px;
  color: #000;
  font-weight: bold;
}

.container-cp-input {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-htq-wrapper {
  position: relative;
  /* margin-top: 10px; */
  padding: 20px 20px;
  background-image: url("../public/assets/images/how-to-bg.png");
  /* -webkit-linear-gradient(bottom, #547aa1, #4773a0); */
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 10;
}

.container-htq-points {
  margin-top: 30px;
  /* margin-left: 20px; */
  color: #ffff;
  font-size: 21px;
  font-weight: 500;
  list-style-type: none;
  line-height: 39px;
}

.container-htq-points li::before {
  content: "";
  display: inline-block;
  list-style-type: circle;
  height: 18px !important;
  width: 18px !important;
  background-color: #fff;
  border-radius: 10px;
  margin-right: 10px;
}

.container-sup-wrapper {
  background-color: #e1e8f5;
  padding-bottom: 1rem;
}

.container-wwr-wrapper {
  /* margin-top: 10px; */
  /* padding-top: 30px; */
  background-image: url("../public/assets/images/app-bg.png");
  /* -webkit-linear-gradient(bottom, #547aa1, #4773a0); */
  background-size: cover;
  background-repeat: no-repeat;
}
.container-sup-title {
  color: #101e42;
  font-size: 38px;
  font-weight: 600;
}

.container-sup-image {
  /* width: 22% !important; */
  width: 85px !important;
}

.container-sup-heading {
  color: #101e42;
  font-weight: bolder;
  display: flex;
  font-size: 32px;
  padding: 0 !important;
}

.container-sup-sub-heading {
  color: #101e42;
  font-weight: 500;
  margin-top: 0.4em;
  font-size: 1.4em;
  text-align: left;
  line-height: 24px;
}

.sub-content-text-p {
  color: #ffff;
  font-size: 21px;
  margin: 0 0 21px 0;
  line-height: 32px;
}
.support-wrapper {
  margin-top: 20px;
  padding-top: 40px;
  background-color: #101e42;
}

.support-logo-image {
  width: 50px;
  height: auto;
}

.support-logo-image-c {
  height: 60px;
  object-fit: contain;
  margin-top: 8px;
}

.support-text {
  color: #ffff;
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.support-link-text {
  text-decoration: none;
  color: #fff;
}

.support-disclaimer {
  padding-top: 35px;
  padding-bottom: 35px;
  color: #fff;
  font-size: 17px;
  text-align: center;
}

p {
  margin-bottom: 0 !important;
  padding: 0;
  margin-left: 10px;
}

.footer-wrapper {
  background-color: #0b142b;
  height: 80px;
}

.footer-content {
  color: #fff;
  font-size: 21px;
}

.cov-wrapper {
  /* background-image: url("../public/assets/images/app-bgnew.png"),
    -webkit-linear-gradient(bottom, #547aa1, #4773a0); */
  background-image: url("../public/assets/images/app-bgnew.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  display: inline-table;
  background-position: center;
}

.cov-container {
  width: 319.4px;
  height: auto;
  border: 5px solid #dbfa66;
  border-radius: 30px;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 20px 0px;
}

.cov-title {
  color: #6485f9;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.app-device-subtitle {
  color: #6485f9;
  text-align: center;
  font-weight: bold;
  line-height: 1;
  font-size: 22px;
  padding-top: 0;
}

.app-plan-subtitle {
  color: #000;
  text-align: center;
  font-weight: 500;
  line-height: 1;
  font-size: 16px;
  padding-top: 0;
}

.app-review-subtitle {
  color: #000;
  text-align: center;
  font-weight: 500;
  line-height: 1;
  font-size: 16px;
  padding-top: 0;
}
.cov-subcontent {
  text-align: center;
}

.cov-btn {
  display: flex;
  align-items: center;
  margin: 10px auto;
  height: 40px;
  padding: 5px 112px;
  background-color: #dbfa66;
  border-radius: 40px 40px;
  color: #101e42;
  font-size: 18px;
  width: 90%;
  margin-bottom: 30px;
}

.cov-text {
  color: #818596;
  font-size: 17px;
  font-weight: 500;
}

.cov-excellent {
  color: #dbfa66;
  font-size: 17px;
  font-weight: 500;
}

.cov-footer {
  font-size: 12px;
  text-align: center;
  padding: 0;
  color: #fff;
  background-color: #434c6d;
  position: static;
  right: 0;
  bottom: 0;
  left: 0;


}

.cov-btn:hover,
.cov-btn:focus {
  background-color: #101e42 !important;
  color: #dbfa66 !important;
}

.suc-title {
  padding-top: 10px;
  color: #6485f9;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.suc-subcontent {
  margin-top: 6px;
  font-size: 15px;
  text-align: center;
}

.suc-btn {
  display: flex;
  align-items: center;
  margin: 30px auto;
  height: 40px;
  padding: 5px 118px;
  background-color: #fe3969;
  border-radius: 40px 40px;
  color: #fff;
  font-size: 18px;
}

.suc-btn:hover,
.suc-btn:focus {
  color: #fff !important;
}

.header-img {
  width: 48px;
  height: 28px;
}

.scrolled {
  transform: scale(0.7);
}
.disclosure-section {
  overflow: scroll;
  height: 350px;
}
.disclosure-section::-webkit-scrollbar {
  display: none;
}
.ant-progress .ant-progress-steps-outer {
  box-shadow: inset 0 0 15px 0 #a9a9a9;
  border: 2px solid #bcbec0;
  border-radius: 6px;
  padding: 2px;
}
.ant-progress-steps-item {
  background: #bcbec0;
}
.ant-progress-steps-item:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.ant-progress .ant-progress-steps-item-active:nth-child(1) {
  background: linear-gradient(to right, #047ad3, #1886c8);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.ant-progress .ant-progress-steps-item-active:nth-child(2) {
  background: linear-gradient(to right, #1e8ac5, #3296bb);
}
.ant-progress .ant-progress-steps-item-active:nth-child(3) {
  background: linear-gradient(to right, #3b9bb6, #51a8ab);
}
.ant-progress .ant-progress-steps-item-active:nth-child(4) {
  background: linear-gradient(to right, #5baea6, #6eb99c);
}
.ant-progress .ant-progress-steps-item-active:nth-child(5) {
  background: linear-gradient(to right, #8fcd8b, #9fd782);
}
.ant-progress .ant-progress-steps-item-active:nth-child(6) {
  background: linear-gradient(to right, #bae775, #c5ed6f);
}
.ant-progress .ant-progress-steps-item-active:nth-child(7) {
  background: linear-gradient(to right, #d7f866, #dcfb63);
}
.ant-progress .ant-progress-steps-item-active:nth-child(8) {
  background: linear-gradient(to right, #dcf863, #deee63);
}
.ant-progress .ant-progress-steps-item-active:nth-child(9) {
  background: linear-gradient(to right, #e0e764, #e5ca65);
}
.ant-progress .ant-progress-steps-item-active:nth-child(10) {
  background: linear-gradient(to right, #eaae65, #f08b66);
}
.ant-progress .ant-progress-steps-item-active:nth-child(11) {
  background: linear-gradient(to right, #f28467, #f47667);
}
.ant-progress .ant-progress-steps-item-active:nth-child(12) {
  background-color: #f85f68;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.ant-progress-line .ant-progress-outer .ant-progress-inner div.ant-progress-bg {
  height: 22px !important;
}

.header-img-align {
  position: absolute;
  top: 30px;
  right: 75px;
}

.header-img-align-a {
  position: absolute;
  top: 30px;
  right: 30px;
}

.con-btn {
  padding: 0 48px;
  background-color: #dbfa66;
  border-radius: 40px 40px;
  color: #101e42;
}
.con-btn.enabled:hover,
.con-btn.enabled:focus {
  background-color: #101e42 !important;
  color: #dbfa66 !important;
}
.model-payment {
  text-align: center !important;
}
.ant-modal .ant-modal-close-x {
  display: none !important;
}
.pay-btn {
  padding: 10px 70px;
  background-color: #fe3969;
  border-radius: 40px 40px;
  color: #fff;
  border: none;
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.pay-btn.enabled:hover,
.pay-btn.enabled:focus {
  background-color: #1cc35c !important;
}

.cont-btn {
  padding: 0 30px;
  background-color: #fe3969;
  border-radius: 40px 40px;
  color: #fff;
  font-size: 12px;
}
.cont-btn.enabled:hover,
.cont-btn.enabled:focus {
  background-color: #1cc35c !important;
}

.con-b-btn {
  padding: 0 48px;
  border-radius: 40px 40px;
  background-color: #818594;
  color: #fff;
}

.con-b-btn:hover,
.con-b-btn:focus {
  color: #121111 !important;
}

.divider {
  border-bottom: 3.48px solid #dbfa66;
  border-radius: 5px;
  width: 228px;
  margin: 0 auto;
}
.con-title {
  padding-top: 20px;
  color: #6485f9;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
}

.con-subtitle {
  padding-left: 12px;
  color: #6485f9;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
}

.con-subcontent {
  padding: 12px 0;
  font-size: 14px;
  text-align: center;
}

.con-card {
  width: 95%;
  height: 170px;
  margin-left: 7px;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  background-color: #e1e8f5;
  overflow-x: auto;
}

.pay-detail-card {
  width: 95%;
  height: 388px;
  margin-left: 7px;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  background-color: #e1e8f5;
  overflow-x: auto;
}

.con-name {
  width: fit-content;
  display: flex;
  justify-content: space-between;
}

.con-card-content {
  font-size: 12px;
  padding-left: 3px;
}
.eli-dob-label {
  margin-bottom: 7px;
  color: #87a1fa;
  font-size: 12px;
  font-weight: 600;
}

.eli-dob-label:before {
  content: "*";
  color: #ff4d4f;
  display: inline-block;
  margin-inline-end: 4px;
  font-size: 18px;
  position: relative;
  top: 6px;
}

.form-label {
  font-size: 13px;
}
.ant-input, .ant-input-affix-wrapper {
  border-radius: 40px 40px !important;
  background-color: #e1e8f5 !important;
  /* box-shadow: inset 0 0 15px 0 #a9a9a9; */
}
.ant-input-affix-wrapper >input.ant-input{
  border-radius: 40px 40px !important;
  background-color: #e1e8f5 !important;
  /* box-shadow: inset 0 0 15px 0 #a9a9a9; */
}

.ant-form-item-label > label {
  color: #87a1fa !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.ant-form-item {
  margin-bottom: 18px;
}
.ant-form label {
  font-size: 13px;
}
.ant-checkbox {
  margin-left: 10px;
  margin-top: 1%;
  margin-bottom: auto;
}

.ant-checkbox-wrapper {
  width: auto;
  margin-top: 0;
 font-size: 12px;
  border-radius: 10px;
  padding: 8px;
  display: flex;
}
.ant-form-item-explain-error {
  font-size: 10px;
}

.ant-select-selector {
  border-radius: 40px 40px !important;
  background-color: #e1e8f5 !important;
}
.eli-subcontent {
  padding: 12px 0;
  font-size: 14px;
  text-align: center;
  line-height: 16px;
}
.program-container {
  margin: 0 auto;
  min-width: 309.8px;
  min-height: 284px;
}

.pro-item {
  height: auto;
  padding: 4px 11px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px 40px;
  background-color: #e1e8f5;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sub-content-text-title {
  color: #fff;
  font-weight: 600;
  margin-top: 4rem;
  font-size: 32px;
  line-height: 20px;
  text-align: center;
}

.dot-steps {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.step {
  flex: 1;
  text-align: center;
  position: relative;
}

.step::before {
  content: "";
  width: 16px;
  height: 16px;
  background-color: #bbc0d5; /* Default dot color */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.completed::before {
  background-color: #6080f0;
}

/* Add connector lines */
.step::after {
  content: "";
  width: 100%;
  height: 5px;
  background-color: #bbc0d5; /* Default line color */
  position: absolute;
  top: 50%;
  left: calc(50% + 8px); /* Adjust the position to align with the dot */
  transform: translateY(-50%);
  z-index: 0;
}

.step.completed::after {
  background-color: #6080f0;
}

.step:last-child::after {
  display: none; /* Hide the line after the last step */
}
.selected {
  background-color: #fe3969;
  color: #fff;
}

.custom-scrollbar {
  scrollbar-width: thin;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}
.custom-x-scrollbar {
  scrollbar-width: thin;
}

.custom-x-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.custom-x-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.coverage-img img {
  width: 300px;
}

.nv-com-btn {
  border-radius: 40px 40px;
  width: 280px;
  background-color: #20a849;
  color: #fff;
  transition: all 350ms ease-in-out;
}

.nv-com-btn:hover,
.nv-com-btn:focus {
  color: #fff !important;
  transform: scale(1.018);
}

.nv-com-retry {
  transition: all 350ms ease-in-out;
}

.nv-com-retry:hover,
.nv-com-retry:focus {
  transform: scale(1.021);
  color: #fe3969 !important;
}
.pricing-table {
  display: flex !important;
  justify-content: center;
  flex-flow: row wrap;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  background: #fff;
}

.pricing-table .ptable-item {
  width: 96% !important;
  padding: 0 15px;
  margin-bottom: 20px;
}

.pricing-table .ptable-single {
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: 0.5s;
  border: 5px solid #0978d4;
  border-radius: 32px 32px 18px 18px;
}

.pricing-table .ptable-header,
.pricing-table .ptable-body,
.pricing-table .ptable-footer {
  position: relative;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.pricing-table .ptable-status,
.pricing-table .ptable-title,
.pricing-table .ptable-price,
.pricing-table .ptable-description,
.pricing-table .ptable-action {
  position: relative;
  width: 100%;
  text-align: center;
}

.pricing-table .ptable-single {
  background: #f6f8fa;
}

.pricing-table .ptable-single:hover {
  box-shadow: 0 0 10px #999999;
  /* transform: scale(1.1); */
}

.pricing-table .ptable-header {
  height: auto;
  padding: 20px 0 20px 0;
  width: auto;
  background: #0978d4;
  /* background: linear-gradient(
    90deg,
    rgba(255, 187, 1, 1) 0%,
    rgba(255, 146, 34, 1) 40%,
    rgba(255, 55, 18, 1) 100%
  ); */
  border-radius: 28px 28px 18px 18px;
}

.ptable-description .prtable-description {
  color: #0978d4;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
}
.pricing-table .prtable-footer {
  height: 80px;
  padding: 15px 0 45px 0;
  width: auto;
  color: #fff;
  text-align: center;
  background: #0978d4;
  border-radius: 22px 22px 12px 12px;
}

.prtable-footer-title {
  font-size: 23px;
  font-weight: 500;
}

.prtable-footer-subcontent {
  margin-top: -5px;
  padding: 0;
}

.pricing-table .ptable-header::before,
.pricing-table .ptable-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 100px solid #f6f8fa;
}

.pricing-table .ptable-header::before {
  right: 50%;
}

.pricing-table .ptable-header::after {
  left: 50%;
}

.pricing-table .ptable-item.featured-item .ptable-header {
  background: #ff6f61;
}

.pricing-table .ptable-status {
  margin-top: -30px;
}

.pricing-table .ptable-status span {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
  padding: 5px 0;
  text-align: center;
  color: #ff6f61;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  background: #2a293e;
}

.pricing-table .ptable-status span::before,
.pricing-table .ptable-status span::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 10px solid #ff6f61;
}

.pricing-table .ptable-status span::before {
  right: 50%;
  border-right: 25px solid transparent;
}

.pricing-table .ptable-status span::after {
  left: 50%;
  border-left: 25px solid transparent;
}

.pricing-table .ptable-title h4 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px;
}

.pricing-table .ptable-price h4 {
  margin: 0;
  color: #ffffff;
  font-size: 21px;
  font-weight: 600;
  margin-left: 15px;
}

.pricing-table .ptable-price h4 small {
  font-size: 14px;
  font-weight: 300;
  margin-top: 16px;
  margin-left: -15px;
}

.pricing-table .ptable-price h4 span {
  margin-left: 3px;
  font-size: 11px;
  font-weight: 300;
}

.pricing-table .ptable-body {
  padding: 20px 0;
}

.pricing-table .ptable-description ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  margin-left: 10px;
}

.pricing-table .ptable-description ul li {
  color: #2a293e;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  padding: 7px;
  /* border-bottom: 1px solid #dedede; */
}

.pricing-table .ptable-description ul li:last-child {
  border: none;
}

.pricing-table .ptable-footer {
  padding-bottom: 30px;
}

.pricing-table .ptable-action {
  color: #fff;
  letter-spacing: 2px;
  /* background: linear-gradient(
    90deg,
    rgba(255, 187, 1, 1) 0%,
    rgba(255, 146, 34, 1) 40%,
    rgba(255, 55, 18, 1) 100%
  ); */
  background: #0978d4;
  border: none;
}

.pricing-table .ptable-action:hover {
  color: #fff !important;
  border: none;
}

.pricing-table .ptable-item.featured-item .ptable-action {
  color: #2a293e;
  background: #ff6f61;
}

.pricing-table .ptable-item.featured-item .ptable-action:hover {
  color: #ff6f61;
  background: #2a293e;
}

.plan-btn:hover {
  background-color: #101e42 !important;
  color: #dbfa66 !important;
}
.border-active {
  background-color: #dbfaaa !important;
  background-color: transparent;
}
.fa-check:before {
  content: "\f00c";
  color: #0ea8f0;
  margin-right: 6px;
}

.ant-carousel .slick-dots li button {
  background: #000 !important;
}
.ant-carousel {
  width: 100%;
  /* position: absolute !important; */
}

.cov-container::-webkit-scrollbar {
  display: none;
}

.ant-carousel .slick-dots li button {
  background: grey !important;
  opacity: 1 !important;
  height: 15px !important;
  width: 15px !important;
  border-radius: 50px !important;
}

.slick-dots li.slick-active button {
  background: black !important;
}
.ant-carousel .slick-dots li.slick-active {
  width: 16px !important;
}
@media (max-width: 947px) {
  .cov-footer {
    position: unset;
  }
}

@media (min-width: 1600px) and (max-width: 2160px) {
  .cov-footer {
    position: unset;
  }
}

@media (max-width: 992px) {
  .pricing-table .ptable-item {
    width: 90.33%;
  }
}

@media (max-width: 768px) {
  .pricing-table .ptable-item {
    width: 90.33%;
  }
}

@media (max-width: 576px) {
  .pricing-table .ptable-item {
    width: 100%;
  }
}

@media (max-width: 680px) {
  .header-img-align {
    display: none;
  }
  .semicircle {
    background-color: #6180ee;
  }
  .semicircle-a {
    background-color: #5871c7;
  }
  /* .cov-container {
    width: fit-content;
  } */
}

@media (max-width: 720px) {
  /* #root {
    overflow: hidden;
  } */
  body {
    width: fit-content;
    height: fit-content;
    background-color: #dbfa66 !important;
    overflow-x: hidden;
  }
  .toggle-btn {
    text-align: center !important;
    margin-left: 1.8rem;
  }
  .display-image {
    display: none;
  }
  .sub-content-a {
    font-size: 3rem;
  }
  .sub-content-ab {
    font-size: 3rem;
    text-align: center;
  }
  .sub-content-b {
    font-size: 2.9rem;
    line-height: 48px;
    text-align: center;
  }
  .sub-content-text {
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
  }
  .sub-content-input {
    font-size: 18px !important;
  }
  .tg-btn-enroll {
    font-size: 14px;
  }
  .sub-content-text-a {
    margin-top: 10px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  .sub-content-last {
    font-size: 16px;
    text-align: center;
    padding: 0;
  }
  .sub-content-text-title {
    color: #fff;
    font-weight: 600;
    margin-top: 16px;
    font-size: 22px;
    line-height: 28px;
  }
  .container-cp-title {
    padding-top: 18px;
    font-size: 33px;
  }
  .container-cp-content {
    font-size: 18px;
    text-align: center;
  }
  .container-cp-card-title {
    font-size: 1.8rem;
    line-height: 32px;
  }
  .container-wrapper {
    padding-bottom: 60px;
  }
  .container-cp-li {
    font-size: 21px;
    gap: 8px;
  }

  .app-plan-li {
    font-size: 21px;
  }

  .container-htq-points {
    font-size: 15px;
    line-height: 28px;
  }
  .container-sup-title {
    font-size: 28px;
  }
  .container-sup-image {
    width: 18% !important;
    position: relative;
    top: 23px;
  }
  .container-sup-heading {
    font-size: 20px;
    /* margin-bottom: -16px; */
    margin-left: 10px;
  }
  .container-sup-sub-heading {
    font-size: 18px;
    margin-left: 90px;
    margin-top: -15px;
  }
  .sub-content-text-p {
    font-size: 18px;
    line-height: 26px;
  }

  .sub-content-text-title {
    text-align: center;
    font-size: 21px;
  }
  .sub-content-input {
    width: 100%;
    height: 70px;
    font-size: 26px;
    margin-bottom: 14px;
    text-align: center;
  }
  .sub-content-button {
    width: 100%;
    height: 70px;
    background-color: #dbfa66;
    color: #101e42;
  }
  .ant-space-compact {
    display: flow-root !important;
  }
  .asterisk {
    top: 92px;
  }
}
@media (min-width: 768px) and (max-width: 1191px) {
  .container-cp-li {
    font-size: 14px;
  }
  .container-cp-card-title {
    font-size: 18px !important;
    border-radius: 22px 22px 18px 18px;
    padding: 5px 10px;
    min-width: 260px;
  }
  .container-cp-card {
    height: 343px;
    box-shadow: rgba(100, 100, 111, 0.8) 0px 7px 29px 0px;
  }

  .container-cp-img {
    width: 26px;
    height: 26px;
    margin-bottom: 5px;
  }
  .app-plan-img {
    width: 18px;
    height: 18px;
  }
}
@media (min-width: 1199px) and (max-width: 1479px) {
  .section-img-1 img {
    width: 647px;
  }
  .sub-content-ab {
    font-size: 4em;
  }
}
@media (min-width: 720px) and (max-width: 1200px) {
  .toggle-btn {
    margin-left: 110px;
  }
  .sub-content-button {
    width: 514px !important;
  }
  .sub-content-input {
    width: 514px !important;
  }
  .ant-space-compact {
    display: inline-grid;
  }
  .container-sup-heading {
    font-size: 22px;
  }
  .container-sup-sub-heading {
    font-size: 15px;
  }
  .tg-btn-enroll {
    margin-right: inherit;
    margin-left: -2.5rem;
  }
  .sub-content-ul {
    margin-bottom: 60px;
  }
}
@media (max-width: 1200px) {
  .container-cp-card-title {
    font-size: 28px;
  }
  .section-img-1,
  .section-img-2,
  .section-img-3 {
    display: none;
  }

  .sub-content-button {
    width: 100%;
  }

  .semicircle,
  .semi-concave,
  .semi-content,
  .semicircle-a,
  .semi-content-a,
  .semi-concave-a {
    display: none;
  }
}

@media (min-width: 1901px) {
  .semicircle,
  .semi-concave,
  .semi-content,
  .semicircle-a,
  .semi-content-a,
  .semi-concave-a {
    display: none;
  }
  .section-img-1 img {
    /* bottom: -20px; */
    width: 450px;
  }
  .cov-footer {
    position: unset;
    bottom: 0;
  }
  .sub-content-ul {
    padding-bottom: 32px;
  }
}

@media (max-width: 550px) {
  .container-cp-card {
    min-width: 388px;
  }
}

@media (min-width: 1880px) {
  .container-wrapper {
    padding-right: 20px;
  }
}

@media (min-width: 1191px) and (max-width: 1254px) {
  .container-cp-card-title {
    font-size: 1.65rem;
  }
}
@media (min-width: 1478px) and (max-width: 1900px) {
  .semicircle-a {
    background: linear-gradient(
      90deg,
      rgba(12, 59, 114, 1) 0%,
      rgba(11, 64, 122, 1) 100%
    );
  }
}

@media (min-width: 1435px) and (max-width: 1478px) {
  .semicircle-a {
    background: linear-gradient(
      90deg,
      rgba(10, 68, 128, 1) 0%,
      rgba(11, 62, 118, 1) 100%
    );
  }
}
@media (min-width: 1388px) and (max-width: 1435px) {
  .semicircle-a {
    background: linear-gradient(
      90deg,
      rgba(10, 70, 131, 1) 0%,
      rgba(11, 64, 121, 1) 100%
    );
  }
}
@media (min-width: 1350px) and (max-width: 1388px) {
  .semicircle-a {
    background: linear-gradient(
      90deg,
      rgba(9, 72, 135, 1) 0%,
      rgba(10, 66, 125, 1) 100%
    );
  }
}
@media (min-width: 1300px) and (max-width: 1350px) {
  .semicircle-a {
    background: linear-gradient(
      90deg,
      rgba(9, 74, 137, 1) 0%,
      rgba(10, 68, 128, 1) 100%
    );
  }
}
@media (min-width: 1288px) and (max-width: 1300px) {
  .semicircle-a {
    background: linear-gradient(
      90deg,
      rgba(9, 75, 139, 1) 0%,
      rgba(10, 69, 129, 1) 100%
    );
  }
}
@media (min-width: 1201px) and (max-width: 1233px) {
  .semicircle-a {
    background: linear-gradient(
      90deg,
      rgba(8, 78, 145, 1) 0%,
      rgba(9, 73, 136, 1) 100%
    );
  }
}

@media (min-width: 1200px) {
  .container-htq-wrapper .container-cp-input {
    justify-content: left;
  }
  .container-htq-wrapper .sub-content-text-title {
    text-align: left;
  }
  .container-wwr-wrapper .sub-content-text-title {
    text-align: left;
  }
  .container-wwr-wrapper .container-cp-input {
    justify-content: left;
  }
}

@media (min-width: 721px) and (max-width: 1095px) {
  .container-cp-title {
    font-size: 46px;
  }
  .container-cp-content {
    font-size: 19px;
  }
}

@media (min-width: 1199px) and (max-width: 1336px) {
  .sub-content-b {
    font-size: 56px;
  }
}

@media (max-width: 768px) {
  .support-logo-image-c {
    display: flex;
    justify-content: left;
  }

  .support-text {
    display: flex;
    justify-content: left;
    align-items: left;
  }
}
.main-container {
	
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
}
.check-container {
	width: 6.25rem;
	height: 7.5rem;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: space-between;

	.check-background {
		width: 100%;
		height: calc(100% - 1.25rem);
		background: linear-gradient(to bottom right, #5de593, #41d67c);
		box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
		transform: scale(0.84);
		border-radius: 50%;
		animation: animateContainer 0.75s ease-out forwards 0.75s;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;

		svg {
			width: 65%;
      margin-left: 15px;
			transform: translateY(1.25rem);
			stroke-dasharray: 80;
			stroke-dashoffset: 80;
			animation: animateCheck 0.35s forwards 1.25s ease-out;
		}
	}

	.check-shadow {
		bottom: calc(-15% - 5px);
		left: 0;
		border-radius: 50%;
		background: radial-gradient(closest-side, rgba(73, 218, 131, 1), transparent);
		animation: animateShadow 0.75s ease-out forwards 0.75s;
	}
}

@keyframes animateContainer {
	0% {
		opacity: 0;
		transform: scale(0);
		box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
	}
	25% {
		opacity: 1;
		transform: scale(0.9);
		box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
	}
	43.75% {
		transform: scale(1.15);
		box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
	}
	62.5% {
		transform: scale(1);
		box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
	}
	81.25% {
		box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
	}
	100% {
		opacity: 1;
		box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
	}
}

@keyframes animateCheck {
	from {
		stroke-dashoffset: 80;
	}
	to {
		stroke-dashoffset: 0;
	}
}

@keyframes animateShadow {
	0% {
		opacity: 0;
		width: 100%;
		height: 15%;
	}
	25% {
		opacity: 0.25;
	}
	43.75% {
		width: 40%;
		height: 7%;
		opacity: 0.35;
	}
	100% {
		width: 85%;
		height: 15%;
		opacity: 0.25;
	}
}

.disclose-container {
  max-height: 284px;
  overflow-y: scroll;
  line-height: 20px;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  font-size: 12px;
  color: #222;


}

.disclose-container p {
  margin-bottom: 10px !important;
  
}

.radio-custom .ant-radio-wrapper {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
}

.radio-custom .ant-radio-wrapper-checked {
  border: 5px solid #dbfa66;
  border-radius: 8px;
  padding: 4px;
}

.radio-custom .ant-radio {
  position: absolute;
  left: -9999px;
  overflow: hidden;
}
